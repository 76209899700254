import React, { useRef, useState, useEffect, Fragment } from "react";
import { Table, Pagination, Badge, Dropdown, Spinner, Button } from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

import { format, set } from "date-fns";

import { getProfile } from "../../../store/actions/AuthActions";

import DTable from "../Table";
import { exportToExcel } from "react-json-to-excel";

//const Orders = () => {
const WalletRecord = ({ props, history }) => {
  const dispatch = useDispatch();
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [statistics, setStatistics] = useState({});  

  // Data Table Start
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 25, 50, 100];
  const [maxPage, setMaxPage] = useState(1);

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(0);
  };

  useEffect(() => {
    loadHistory();
  }, [page, pageSize]);

  useEffect(() => {
    loadStatistics();
    dispatch(getProfile());
    loadHistory();
  }, [dispatch]);

  const loadStatistics = async () => {
    try {
      const { data } = await axiosInstance.get("master-reseller/wallet-record/statistics");
      if (data.status) {
        setStatistics(data.statistics);
      }else{
        setStatistics({});
      }
    } catch (e) {
      setStatistics({});
      console.log("loadStatistics :: ",e);
    }
  }

  const loadHistory = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        "master-reseller/wallet-record",
        {
          search: search,
          page: page,
          limit: pageSize,
        }
      );

      if (data.status) {
        setHistoryData(data.record);
        var maxPage2 = Math.ceil(data.total / pageSize);
        console.log("max page - " + maxPage2);
        setMaxPage(maxPage2);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log("loadHistory :: ",e);
      setHistoryData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadHistory();
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  const columns = [
    {
      Header: "SN",
      accessor: "_id",
      sort: false,
      accessor: "",

      Cell: (row) => {
        return <div>{Number(row.row.id) + page * pageSize + 1}</div>;
      },
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row }) => {
        const d = row.original;
        return d.type.toUpperCase();
        //return <input  className="form-control" style={{ width: '40%', textAlign: 'center' }} type="text" value={d.key} readOnly={true}></input>;
      },
    },
    {
      Header: "Wallet",
      accessor: "wallet",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <strong className={textColor(d.amount)}>
            {d.amount > 0 ? "+" : ""}
            {d.amount}
          </strong>
        );
      },
    },
    {
      Header: "Balance",
      accessor: "balance",
      sort: false,
      Cell: ({ row }) => {
        const d = row.original;
        return (
          <strong className={textColor(d.balance)}>
            {d.balance > 0 ? "+" : ""}
            {d.balance}
          </strong>
        );
      },
    },

    {
      Header: "Reseller",
      accessor: "reseller",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.resellerId ? (
          <Link to={"reseller/" + d.resellerId.id}>
            {" "}
            {d.resellerId.name}{" "}
          </Link>
        ) : (
          ""
        )
      },
    },

    {
      Header: "Note",
      accessor: "note",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.note;
      },
    },

    {
      Header: "Created",
      accessor: "createdAt",
      sort: true,
      Cell: ({ row }) => {
        const d = row.original;
        return d.createdAt
          ? format(new Date(d.createdAt), "dd/MM/yyyy H:mma")
          : "No";
      },
    },
  ];

  function textColor(amount) {
    if (amount > 0) {
      return "text-success";
    } else {
      return "text-danger";
    }
  }

  const onExport = async () => {
    try {
      const { data } = await axiosInstance.post(
        "master-reseller/wallet-record",
        {
          search: search,
          page: 0,
          limit: (maxPage * pageSize) + 1000 ,
        }
      );
      if (data.status) {
        var exportData = data.record.map((item) => {
          return {
            ID: item._id,
            Type: item.type.toUpperCase(),
            Wallet: item.amount,
            Balance: item.balance,
            Reseller: item.resellerId ? item.resellerId.name : "",
            Note: item.note,
            OrderId: item.orderId,
            Created: item.createdAt
              ? format(new Date(item.createdAt), "dd/MM/yyyy H:mma")
              : "No",
          };
        });
        exportToExcel(exportData,`wallet-record-${format(new Date(), "dd-MM-yyyy")}`);
        swal("Success", "File Downloaded", "success");
      }
    } catch (e) {
      swal("Error", "Something went wrong", "error");
    }
  };

  return (
    <Fragment>
      {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

      <div className="col-12">

      <div className="row">
              <div className="col-xl-3 col-md-6">
                <div className="card bordered-card">
                 
                    <div className="card-body p-4">
                      <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                          {statistics ? statistics.total ?? 0 : 0}
                        </h2>
                        <span className="circle-1 bg-primary mt-4" />
                      </div>

                      <span className="fs-14">Total Wallet Balance</span>
                    </div>
                 
                </div>
              </div>

              <div className="col-xl-3 col-md-6">
                <div className="card bordered-card">
                
                    <div className="card-body p-4">
                      <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                          {statistics ? statistics.buying ?? 0 : 0}
                        </h2>
                        <span className="circle-1 bg-success mt-4" />
                      </div>

                      <span className="fs-14">Total Wallet Buying</span>
                    </div>
                 
                </div>
              </div>

              <div className="col-xl-3 col-md-6">
                <div className="card bordered-card">
                 
                    <div className="card-body p-4">
                      <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                          {statistics ? statistics.recharge ?? 0 : 0}
                        </h2>
                        <span className="circle-1 bg-success mt-4" />
                      </div>

                      <span className="fs-14">Total Wallet Recharge</span>
                    </div>
                 
                </div>
              </div>

              <div className="col-xl-3 col-md-6">
                <div className="card bordered-card">
                 
                    <div className="card-body p-4">
                      <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                          {statistics ? statistics.spend ?? 0 : 0}
                        </h2>
                        <span className="circle-1 bg-danger mt-4" />
                      </div>

                      <span className="fs-14">Total Wallet Spend</span>
                    </div>
                 
                </div>
              </div>
            </div>

        <div className="card">
          <div className="card-header">
            <h4 className="card-title mr-auto">Wallet Record</h4>

            <div className="input-group search-area d-lg-inline-flex d-none mr-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchTimeout) {
                      clearTimeout(searchTimeout);
                    }
                    loadHistory();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#A4A4A4"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <Button
                  className="btn btn-primary font-w600 mr-2"
                  onClick={() => onExport()}
                >
                  Export
                </Button>


          </div>
          <div className="card-body">
           

            {loading ? (
              <div className="text-center mt-4">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : historyData.length === 0 ? (
              <p>No wallet Record Found</p>
            ) : (
              
              <DTable
              columns={columns}
              data={historyData}
              pageSize={pageSize}
              isSortable={true}
              pagination={false}
              pageSizes={pageSizes}
              page={page}
              maxPage={maxPage}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />

            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WalletRecord;
